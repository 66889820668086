<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-07-28 09:52:15
 * @LastEditTime: 2021-08-30 16:24:05
 * @Description: 租户管控设置
 * @Param:
 * @FilePath: \JT_Web_test\src\views\admin\tenantControlSettings\index.vue
 -->

<template>
    <div class="tenantControlSettings" v-loading="loading">
        <div class="tenantControlSettingsBox">
            <ul class="tenantControlSettingsNav">
                <template v-for="(item, index) in areaData">
                    <li :key="`nav_${index}`" :class="{'active': selectedArea == index}" @click="selectArea(item, index)">
                        <span>{{ item.org_name }}</span>
                    </li>
                </template>
            </ul>
            <div class="tenantControlSettingsContent">
                <el-tabs v-model="selectedTenantID" @tab-click="selectTenant">
                    <template v-for="(item, index) in tenantData">
                        <el-tab-pane :key="`tenant_${index}`" :label="item.tenant_name" :name="item.tenant_id"></el-tab-pane>
                    </template>
                </el-tabs>
                <div class="timeSetUp" v-if="tenantData.length>0">
                    <h4>管控开启时间：</h4>
                    <el-date-picker
                        v-model="controlTime"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                    <el-button type="primary" @click="setControlTime">
                        确定
                    </el-button>
                    <el-button type="primary" class="open" @click="openAllControls(1)">
                        一键开启
                    </el-button>
                    <el-button type="primary" class="close" @click="openAllControls(0)">
                        一键关闭
                    </el-button>
                </div>
                <div class="controlList" :class="{'noTenant': tenantData.length == 0}">
                    <table>
                        <thead>
                            <tr>
                                <th>合同类型</th>
                                <th>管控名称</th>
                                <th>管控状态</th>
                            </tr>
                        </thead>
                        <template v-if="controlTableData.length > 0">
                            <template v-for="(item, index) in controlTableData">
                                <tbody :key="`controlTableData_${index}`">
                                    <tr>
                                        <td>{{ item.contract_type_name }}</td>
                                        <td>
                                            <template v-for="(k,i) in item.controlList">
                                                <p :key="`controlList_${i}`">
                                                    {{ k.control_name }}
                                                </p>
                                            </template>
                                        </td>
                                        <td>
                                            <template v-for="(k,i) in item.controlList">
                                                <div :key="`elSwitch_${i}`">
                                                    <el-switch
                                                        v-model="k.is_enable"
                                                        active-text="开启"
                                                        inactive-text="关闭"
                                                        @change="singleOpenControl(k)"
                                                    ></el-switch>
                                                </div>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </template>
                        <template v-else>
                            <tbody>
                                <tr>
                                    <td class="noData" colspan="3"></td>
                                </tr>
                            </tbody>
                        </template>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tenant-control-settings',
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            // 片区数据
            areaData: [],
            // 已选片区下标
            selectedArea: 0,
            // 租户数据
            tenantData: [],
            // 已选租户id
            selectedTenantID: '',
            // 已选租户
            selectedTenant: '',
            // 管控时间
            controlTime: '',
            // 管控列表数据
            controlTableData: [],
        };
    },
    watch: {},
    computed: {},
    created() {
        // 获取片区数据
        this.getAreaData();
    },
    mounted() {},
    methods: {
        // 切换片区
        selectArea(data, index) {
            this.selectedArea = index;
            this.loading = true;
            // 获取片区下租户数据
            this.getTenantData(data.id);
        },
        // 选择租户
        selectTenant() {
            this.selectedTenant = this.tenantData.filter(item => item.tenant_id === this.selectedTenantID)[0];
            this.loading = true;
            // 获取管控时间
            this.getControlTime(this.selectedTenantID);
            // 获取管控列表数据
            this.getControlTableData(this.selectedTenantID);
        },
        // 单个开启管控
        singleOpenControl(data) {
            let type = null;
            let text = '';
            if (data.is_enable) {
                type = 1;
                text = '是否开启该管控点?';
            } else {
                type = 0;
                text = '是否关闭该管控点?';
            }
            this.$confirm(text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.singleOpenControlRequest(data, type);
            }).catch(() => {
                data.is_enable = !data.is_enable;
                this.$message({
                    type: 'info',
                    message: '已取消操作',
                });
            });
        },
        // 单个开启管控请求
        singleOpenControlRequest(data, type) {
            this.loading = true;
            this.$axios
                // eslint-disable-next-line max-len
                .put(`/interfaceApi/sale/control_config/modify_config/${data.control_code_config}/${type}?tenant_id=${this.selectedTenant.tenant_id}&tenant_code=${this.selectedTenant.tenant_code}`)
                .then(() => {
                    let text = '';
                    if (type === 1) {
                        text = `${data.control_name} 管控已开启！`;
                    } else {
                        text = `${data.control_name} 管控已关闭！`;
                    }
                    this.$message({
                        message: text,
                        type: 'success',
                    });
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 开启所有管控
        openAllControls(type) {
            let text = '';
            if (type === 1) {
                text = '是否开启全部管控点？';
            } else {
                text = '是否关闭全部管控点？';
            }
            this.$confirm(text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.openAllControlsRequest(type);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作',
                });
            });
        },
        // 开启/关闭所有管控请求
        openAllControlsRequest(type) {
            this.loading = true;
            this.$axios
                .put(`/interfaceApi/sale/control_config/modify_all_config/${type}?tenant_id=${this.selectedTenant.tenant_id}`)
                .then(() => {
                    this.getControlTableData(this.selectedTenant.tenant_id);
                    let text = '';
                    if (type === 1) {
                        text = '所有管控已开启！';
                    } else {
                        text = '所有管控已关闭！';
                    }
                    this.$message({
                        message: text,
                        type: 'success',
                    });
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取片区数据
        getAreaData() {
            this.loading = true;
            this.$axios
                .get('/interfaceApi/basicplatform/orgtree/orgtype/OT000001/3?page=1&row=9999')
                .then(res => {
                    if (res && (res.List || []).length > 0) {
                        this.areaData = res.List;
                        // 获取片区下租户数据
                        this.getTenantData(this.areaData[0].id);
                    } else {
                        this.areaData = [];
                        this.tenantData = [];
                        this.selectedTenantID = '';
                        this.selectedTenant = {};
                        this.controlTableData = [];
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取片区下租户数据
        getTenantData(treeid) {
            this.$axios
                .get(`/interfaceApi/tenant/tenantorg/tenantbytreeId/${treeid}/40`)
                .then(res => {
                    if ((res || []).length > 0) {
                        this.tenantData = res;
                        this.selectedTenantID = this.tenantData[0].tenant_id;
                        this.selectedTenant = this.tenantData[0];
                        // 获取管控时间
                        this.getControlTime(this.selectedTenantID);
                        // 获取管控列表数据
                        this.getControlTableData(this.selectedTenantID);
                    } else {
                        this.tenantData = [];
                        this.selectedTenantID = '';
                        this.selectedTenant = {};
                        this.controlTableData = [];
                        this.loading = false;
                        this.$message({
                            message: '当前片区下暂无租户！',
                            type: 'warning',
                        });
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取管控时间
        getControlTime(tenant_id) {
            this.$axios
                .get(`/interfaceApi/sale/control_config/get_control_starttime?tenant_id=${tenant_id}`)
                .then(res => {
                    this.controlTime = res;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 设置管控时间
        setControlTime() {
            if ((this.controlTime || '') === '') {
                this.$message({
                    message: '请先选择管控时间！',
                    type: 'warning',
                });
            } else {
                this.$confirm('是否要修改时间？确定后将处理当前所有合同的进度数据！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.setControlTimeRequest();
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作',
                    });
                });

            }
        },
        // 设置管控时间请求
        setControlTimeRequest() {
            this.loading = true;
            this.$axios
            // eslint-disable-next-line max-len
                .put(`/interfaceApi/sale/control_config/set_control_starttime?use_system_time=${this.controlTime}&tenant_id=${this.selectedTenant.tenant_id}&tenant_code=${this.selectedTenant.tenant_code}`)
                .then(() => {
                    this.$message({
                        message: '设置成功！',
                        type: 'success',
                    });
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取管控列表数据
        getControlTableData(tenant_id) {
            this.$axios
                .get(`/interfaceApi/sale/control_config/config_list/${tenant_id}`)
                .then(res => {
                    this.controlTableData = res;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>

<style scoped lang="stylus">
.tenantControlSettings
    width 100%
    padding 0.1rem 0.2rem
    .tenantControlSettingsBox
        width 100%
        height 100%
        background #fff
        display flex
        .tenantControlSettingsNav
            width 1.6rem
            height 100%
            background #F4F7FD
            border 2px solid #fff
            li
                height 0.6rem
                font-size 0.18rem
                display flex
                justify-content center
                align-items center
                cursor pointer
                border-top 1px solid #fff
                border-bottom 2px solid #fff
                i
                    font-size 0.2rem
                    margin-right 0.1rem
                &.active
                    color #FFFFFF
                    border none
                    background linear-gradient(270deg, #55A7FE 0%, #3E79C5 100%)
        .tenantControlSettingsContent
            width calc(100% - 1.6rem)
            height 100%
            >>>.el-tabs
                .el-tabs__header
                    margin-bottom 0
                .el-tabs__nav-wrap
                    padding 0 0.3rem
                    &:after
                        background-color #F0F0F0
                .el-tabs__item
                    height 0.4rem
                    font-size 0.16rem
                    line-height 0.4rem
                    &:hover,
                    &.is-active
                        color #D6000F
                .el-tabs__active-bar
                    background #D6000F
            .timeSetUp
                display flex
                padding 0.14rem 0.2rem 0
                h4
                    font-size 0.16rem
                    line-height 0.36rem
                    color #666666
                .el-date-editor
                    margin-right 0.1rem
                .el-button
                    padding 0 0.2rem
                    line-height 0.36rem
                    border none
                    &.open
                        background linear-gradient(135deg, #3A96FE 0%, #40B6FF 100%)
                    &.close
                        background linear-gradient(129deg, #E06865 0%, #FAAD86 100%)
            .controlList
                margin-top 0.14rem
                padding 0 0.2rem
                height calc(100% - 1.24rem)
                overflow-y auto
                &.noTenant
                    height calc(100% - 0.28rem)
                table
                    width 100%
                    min-height 100%
                    border-collapse collapse
                    tbody
                        &:nth-of-type(even)
                            background #F8FAFD
                    th,td
                        font-size 0.16rem
                        line-height 0.42rem
                        border 1px solid #EBEEF6
                        text-align center
                    th
                        border-color #D7D7D7
                        background #EDF0F5
                        color #022782
                    td
                        color #333333
                        &.noData
                            height 4rem
                            background url('../../../assets/images/default/list_nothing.png') center no-repeat;
                            background-size auto 70%
                        >p,
                        >div
                            border-bottom 1px solid #EBEEF6
                            &:last-child
                                border-bottom none
                        >>>.el-switch
                            width 52px
                            height 20px
                            .el-switch__core
                                width 52px !important
                                height 20px
                            .el-switch__label
                                position: absolute;
                                display: none;
                                color: #fff;
                            .el-switch__label--left,
                            .el-switch__label--right
                                margin 0
                            .el-switch__label--right
                                z-index: 1;
                                right: 19px;
                            .el-switch__label--left
                                z-index: 1;
                                left: 19px;
                            .el-switch__label.is-active
                                display: block;
</style>